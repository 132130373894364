import { Avatar, Chip, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { flatten, get, map, sumBy } from 'lodash-es';
import React from 'react';
import { formatNumber } from '~/components/NumberText';
import { formatUiDate } from '~/lib/date';
import {
  Section,
  SectionContainer,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  Title
} from '~/pages/dashboard/project/EAReport/style';
import { PageBreakBefore, TitleUnderline } from './style';
import { EAReportGroupedMeter } from './types';

const SitesSection: React.FC<{ groupedMeters: EAReportGroupedMeter[] }> = ({ groupedMeters }) => {
  return (
    <>
      <Section id="meters" sx={{ width: '100%', ...PageBreakBefore }}>
        <SectionContainer sx={{ textAlign: 'center' }}>
          <Title variant="h4">SITES</Title>
          <TitleUnderline />
        </SectionContainer>
      </Section>

      <Section>
        <SectionContainer>
          <TableContainer>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    State
                    <br />
                    /Group
                  </StyledTableCell>
                  <StyledTableCell>Trading Name</StyledTableCell>
                  <StyledTableCell>Legal Name</StyledTableCell>
                  <StyledTableCell>ABN/ACN</StyledTableCell>
                  <StyledTableCell>Site Address</StyledTableCell>
                  <StyledTableCell>Suburb</StyledTableCell>
                  <StyledTableCell>State</StyledTableCell>
                  <StyledTableCell>Postcode</StyledTableCell>
                  <StyledTableCell>NMI</StyledTableCell>
                  <StyledTableCell>Current Retailer</StyledTableCell>
                  <StyledTableCell>Contract End Date</StyledTableCell>
                  <StyledTableCell>Annual Usage</StyledTableCell>
                  <StyledTableCell>Total</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedMeters.map(({ meters, name }, index) => (
                  <React.Fragment key={`site-${name}-${index}`}>
                    {map(meters, (meter, index) => (
                      <StyledTableRow
                        key={meter.meterNumber}
                        sx={{ pageBreakAfter: (index + 1) % 5 == 0 ? 'always' : 'avoid' }}
                      >
                        {index === 0 && (
                          <StyledTableCell rowSpan={meters.length}>{name || meter.state}</StyledTableCell>
                        )}
                        <StyledTableCell>{meter.company?.tradingName}</StyledTableCell>
                        <StyledTableCell>{meter.company?.legalName}</StyledTableCell>
                        <StyledTableCell sx={{ textWrap: 'nowrap' }}>
                          {[meter.company?.abn, meter.company?.acn].filter((v) => !!v)?.[0]}
                        </StyledTableCell>
                        <StyledTableCell>{meter.streetAddress}</StyledTableCell>
                        <StyledTableCell>{meter.suburb}</StyledTableCell>
                        <StyledTableCell>{meter.state}</StyledTableCell>
                        <StyledTableCell>{meter.postCode}</StyledTableCell>
                        <StyledTableCell>{meter.meterNumber}</StyledTableCell>
                        <StyledTableCell>{get(meter, 'contract.retailer.name')}</StyledTableCell>
                        <StyledTableCell>{formatUiDate(get(meter, 'contract.contractEndDate'))}</StyledTableCell>
                        <StyledTableCell>
                          <Chip
                            sx={{ mb: 0.5 }}
                            size="small"
                            avatar={<Avatar>P</Avatar>}
                            label={formatNumber(meter.annualData.peakEnergy, 0, '-')}
                          />
                          <br />
                          <Chip
                            sx={{ mb: 0.5 }}
                            size="small"
                            avatar={<Avatar>OP</Avatar>}
                            label={formatNumber(meter.annualData.offPeakEnergy, 0, '-')}
                          />
                          <br />
                          <Chip
                            size="small"
                            avatar={<Avatar>S</Avatar>}
                            label={formatNumber(meter.annualData.shoulderEnergy, 0, '-')}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold', bgcolor: 'grey.A400' }}>
                          {formatNumber(meter.annualData.totalEnergy, 0, '-')}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </React.Fragment>
                ))}

                <TableRow sx={{ bgcolor: 'common.black' }}>
                  <StyledTableCell colSpan={10}></StyledTableCell>
                  <StyledTableCell sx={{ color: 'brand.main', fontWeight: 'bold' }}>Total</StyledTableCell>
                  <StyledTableCell>
                    <Chip
                      sx={{ mb: 0.5, color: 'common.white', fontWeight: 'bold' }}
                      size="small"
                      avatar={<Avatar>P</Avatar>}
                      label={formatNumber(
                        sumBy(flatten(map(groupedMeters, 'meters')), 'annualData.peakEnergy'),
                        0,
                        '-'
                      )}
                    />
                    <Chip
                      sx={{ mb: 0.5, color: 'common.white', fontWeight: 'bold' }}
                      size="small"
                      avatar={<Avatar>OP</Avatar>}
                      label={formatNumber(
                        sumBy(flatten(map(groupedMeters, 'meters')), 'annualData.offPeakEnergy'),
                        0,
                        '-'
                      )}
                    />
                    <Chip
                      sx={{ mb: 0.5, color: 'common.white', fontWeight: 'bold' }}
                      size="small"
                      avatar={<Avatar>S</Avatar>}
                      label={formatNumber(
                        sumBy(flatten(map(groupedMeters, 'meters')), 'annualData.shoulderEnergy'),
                        0,
                        '-'
                      )}
                    />
                  </StyledTableCell>
                  <StyledTableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>
                    {formatNumber(sumBy(flatten(map(groupedMeters, 'meters')), 'annualData.totalEnergy'), 0, '-')}
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </TableContainer>
        </SectionContainer>
      </Section>
    </>
  );
};

export default SitesSection;
