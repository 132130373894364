import { Box, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { capitalize, find, flatten, groupBy, intersection, isEmpty, last, map, sortBy, sum } from 'lodash-es';
import React from 'react';
import NumberText from '~/components/NumberText';
import {
  PageBreakBefore,
  Section,
  SectionContainer,
  StyledTable,
  StyledTableCell,
  StyledTableRow,
  Title,
  TitleUnderline
} from './style';
import { CalculatedProjectOffer, ReportProjectMeter } from './types';

const ContractTermComparisonSection: React.FC<{
  meters: ReportProjectMeter[];
  offers: CalculatedProjectOffer[];
  name: string;
}> = ({ meters, offers, name }) => {
  return (
    <>
      <Section id="contract-period-comparison" sx={PageBreakBefore}>
        <SectionContainer sx={{ textAlign: 'center' }}>
          <Title variant="h4">CONTRACT TERM COMPARISON</Title>
          <TitleUnderline />
          <Typography variant="h5">{name || map(meters, 'meterNumber').join(', ')}</Typography>
        </SectionContainer>
      </Section>

      <Section>
        <SectionContainer>
          {Object.entries(groupBy(flatten(map(offers, 'periods')), 'periodName')).map(
            ([periodName, periods], index) => {
              const sortedPeriods = sortBy(periods, 'annualTotalCosts');
              return (
                <Box key={`period-comparison-${index}`} sx={{ mb: 4, pageBreakInside: 'avoid' }}>
                  <Typography variant="h6" gutterBottom>
                    {periodName} Months
                  </Typography>
                  <TableContainer>
                    <StyledTable>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>Rate Type</StyledTableCell>
                          <StyledTableCell colSpan={3}>kWh Commodity Unit Rate</StyledTableCell>
                          <StyledTableCell>Contract Cost</StyledTableCell>
                          <StyledTableCell>Environmental Costs</StyledTableCell>
                          <StyledTableCell>Retailer & Metering Costs</StyledTableCell>
                          <StyledTableCell>Contestable Total Spend</StyledTableCell>
                          <StyledTableCell>Differential</StyledTableCell>
                          <StyledTableCell>Cost</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ bgcolor: 'brand.main' }}>
                          <StyledTableCell sx={{ color: 'common.white' }}>Offer Rank</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Retailer</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Offered</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Peak</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Off Peak</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Shoulder</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>Ex GST</StyledTableCell>
                          <StyledTableCell sx={{ color: 'common.white' }}>c/kWh</StyledTableCell>
                        </TableRow>
                        {map(sortedPeriods, (period, periodIndex) => {
                          const periodOffer = find(offers, (offer) => {
                            return !isEmpty(
                              intersection(
                                flatten(map(offer.periods, (period) => map(period.splitPeriods, 'id'))),
                                map(period.splitPeriods, 'id')
                              )
                            );
                          });

                          return (
                            <StyledTableRow
                              key={`period-comparison-${periodIndex}`}
                              className={period.isBest === true ? 'highlight' : ''}
                            >
                              <StyledTableCell component="th" scope="row">
                                {periodIndex + 1}
                              </StyledTableCell>
                              <StyledTableCell>{periodOffer?.retailer?.name}</StyledTableCell>
                              <StyledTableCell>
                                {capitalize(period.offerType === 'FLAT' ? 'SMOOTH' : period.offerType)}
                              </StyledTableCell>
                              <StyledTableCell>
                                <NumberText value={last(period.splitPeriods)?.peak} precision={5} na="-" />
                              </StyledTableCell>
                              <StyledTableCell>
                                <NumberText value={last(period.splitPeriods)?.offPeak} precision={5} na="-" />
                              </StyledTableCell>
                              <StyledTableCell>
                                <NumberText value={last(period.splitPeriods)?.shoulder} precision={5} na="-" />
                              </StyledTableCell>
                              <StyledTableCell>
                                $<NumberText value={period.periodEnergyCosts} precision={0} />
                              </StyledTableCell>
                              <StyledTableCell>
                                $<NumberText value={period.periodRenewalCosts} precision={0} />
                              </StyledTableCell>
                              <StyledTableCell>
                                $
                                <NumberText
                                  value={sum([period.periodRetailerServiceFee, period.periodMeteringCharge])}
                                  precision={0}
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                $<NumberText value={period.periodContestableCosts} precision={0} />
                              </StyledTableCell>
                              <StyledTableCell>
                                $
                                <NumberText
                                  value={period.periodContestableCosts - sortedPeriods[0].periodContestableCosts}
                                  precision={0}
                                  na="-"
                                />
                              </StyledTableCell>
                              <StyledTableCell>
                                <NumberText value={period.avgContestableCostsPerKwh} precision={2} />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </Box>
              );
            }
          )}
        </SectionContainer>
      </Section>
    </>
  );
};

export default ContractTermComparisonSection;
